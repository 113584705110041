import React, { useEffect, useState, useRef } from 'react';
import Dropdown from '../Components/Common/Dropdown/Dropdown';
import { ContainerFlat } from './Styles';
import { eventEmiter } from '../rx/rxState';
import { rxPriceError } from '../rx/rxState';

const AttributeDropdown = (props) => {
  const [value, setValue] = useState('');
  const [label, setLabel] = useState('');
  const valueRef = useRef(null);

  useEffect(() => {
    setValue(props.attribute.value);
    valueRef.current = props.attribute.value;
    updateLabel(props.attribute.value);
    return () => {
      // if (props.attribute.visible && props.attribute.id === 'price' && !valueRef.current) {
      //   rxPriceError.next("Please set a price for your product");
      // }
    };
  }, []);

  const updateLabel = (value) => {
    for (const option of props.attribute.options) {
      if (option.value === value) {
        setLabel(option.label);
        break;
      }
    }
  };
  const onClick = (value) => {
    if (props.attribute.id === 'price') {
      rxPriceError.next(null);
    }
    valueRef.current = value.value;
    let attr = props.attribute;
    props.onChange(attr.id, value.value);
    setTimeout(() => {
      props.onChange(attr.id, value.value);
    }, 0);
    setValue(value.value);
    updateLabel(props.attribute.value);

    eventEmiter.next({
      type: 'update-view',
    });
  };
  return (
    <ContainerFlat>
      <div
        className={
          props.attribute.id === 'borderRadius'
            ? 'attribute-control-border-radius'
            : 'attribute-control'
        }
      >
        <Dropdown
          name={props.attribute.displayName}
          initialValue={label}
          options={props.attribute.options}
          onChange={onClick}
        />
      </div>
    </ContainerFlat>
  );
};

export default AttributeDropdown;
