import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams,
} from 'react-router-dom';
import { Amplify } from 'aws-amplify';

// import View from './Canvas/View';
import PageApp from './Pages/PageApp';
import RxStorage from './rx/rxComponent';
import LoginPage from './Pages/PageLogin';
import AICreatePage from './Pages/AICreatePage';

import { AMPLIFY_CLIENT_ID, AMPLIFY_POOL_ID } from './Constants';
import LogInWrapper from 'Components/LogInWrapper/LogInWrapper';
import SingUpWrapper from 'Components/SingUpWrapper/SingUpWrapper';
import StripePlansPage from 'Components/stripePlansPage/StripePlansPage';
import Preview from './Components/Preview/Preview';
import BookGenerator from './Pages/BookGenerator';
import BookEditorView from './Components/books/BookEditorView';
import HtmlRender from 'Pages/HtmlRender';
import BookEditor from 'Pages/BookEditor';
import AiTemplateQuestions from 'Components/AiTemplate/AiTemplateQuestions';
import AiPageQuestions from 'Components/AiTemplate/AiPage';

declare global {
  interface Document {
    cognitoUser: any;
  }
}

let awsconfig = {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: AMPLIFY_POOL_ID,
  aws_user_pools_web_client_id: AMPLIFY_CLIENT_ID,
  oauth: {},
};
Amplify.configure(awsconfig);

interface IProps {
  editMode: string;
  productMode: string;
  productId: string;
}

interface IParams {
  user: any;
  page: string;
  productId: string;
}

const App = (props: IProps) => {
  function PageAppPreviewMode() {
    let { page, productId } = useParams<IParams>();
    return <Preview pageId={productId} />;
  }

  function PageAppEditMode() {
    let { user, page, productId } = useParams<IParams>();
    return (
      <PageApp
        editMode={true}
        page={page}
        productId={productId}
        username={user}
      />
    );
  }

  let productId: string | null = props.productId;
  let page = 'optin';
  if (props.productMode) {
    productId =
      document.getElementById('meta-funnel-id')?.getAttribute('data') || null;
    console.log('LIVE PARAMS', productId);
  }

  return (
    <>
      {props.editMode ? (
        //EDIT mode
        <Router>
          <RxStorage />
          <Switch>
            <Route path="/edit/login/:user/:productId/:page">
              <LoginPage />
            </Route>
            <Route exact path="/edit/generate">
              <Redirect to={`/console`} />
              {/* <AICreatePage /> */}
            </Route>
            <Route path="/edit/generate/:user/:productId/:page">
              <AICreatePage />
            </Route>
            <Route path={`/edit/edit/:user/:productId/:page`}>
              <PageAppEditMode />
            </Route>
            <Route path={`/edit/view/:productId/:page`}>
              <PageAppPreviewMode />
            </Route>
            <Route path={`/edit/login`}>
              <LogInWrapper isModal={false} />
            </Route>
            <Route path={`/edit/signup`}>
              <SingUpWrapper versionStripePage="withInput" />
            </Route>
            <Route path={`/edit/signup2`}>
              <SingUpWrapper versionStripePage="withoutInput" />
            </Route>
            <Route path={`/edit/pricing`}>
              <StripePlansPage />
            </Route>
            <Route path={`/edit/special-discount`}>
              <StripePlansPage discount={true} />
            </Route>
            <Route path={`/edit/no-trial-special`}>
              <StripePlansPage discount={false} />
            </Route>
            <Route path={`/edit/book`}>
              <BookGenerator />
            </Route>
            <Route path={`/edit/html`}>
              <HtmlRender />
            </Route>
            {/* <Route path={`/edit/book-editor`}>
              <BookEditor />
            </Route> */}
            <Route path={`/edit/book-editor/:bookId`}>
              <BookEditor />
            </Route>
            <Route path={`/edit/ai-template/:templateId`}>
              <AiTemplateQuestions />
            </Route>
            <Route path={`/edit/ai-page/:pageId`}>
              <AiPageQuestions />
            </Route>
          </Switch>
        </Router>
      ) : (
        //Live Mode
        <Router>
          {/* REGULAR PAGES */}
          <Switch>
            <Route path={`/edit/l`} exact={false}>
              <PageApp
                editMode={props.editMode}
                page={page}
                productId={productId}
                productMode={props.productMode}
              />
            </Route>
            {/* BOOK PAGES */}
            <Route path={`/edit/bl/:bookId`} exact={false}>
              <BookEditorView liveMode={true} />
            </Route>
            {/*FOR LIVE SERVER BUILD*/}
            <Route path="/*">
              <PageApp
                editMode={props.editMode}
                page={page}
                productId={productId}
                productMode={props.productMode}
              />
            </Route>
          </Switch>
          <RxStorage />
        </Router>
      )}
    </>
  );
};

export default App;
