export const GET_AI_TEMPLATE = {
  operationName: 'getOneAiTemplate',
  query: `query getOneAiTemplate(
      $id: String!
    ) {
      getOneAiTemplate(
        id: $id
      ) {
        questions {
          id
          label
        }
        sections {
          templateName
          content {
            aiField
            content
          }
        }
      }
    }`,
};
