import {
  rxCurrentPage,
  rxPages,
  rxNavigation,
  eventEmiter,
  rxIsLoading,
} from '../rxState';
import { createBrowserHistory } from 'history';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';

const history = createBrowserHistory();

export const changePageName = (state, { newName, oldName }) => {
  rxIsLoading.next('Renaming...');
  const name = newName.trim();
  if (name.length === 0 || name === oldName) {
    return;
  }
  graphQlCall({
    queryTemplateObject: QUERIES.RENAME_SUBPAGE,
    headerType: 'USER-AUTH',
    values: {
      pageId: state.pageId,
      oldName,
      newName,
    },
  })
    .then((res) => {
      const navigation = state.navigation.map((item) => {
        if (item.name === oldName) {
          return {
            name: name,
            url: name,
          };
        }
        return item;
      });

      let newPages = {};

      const oldPages = state.pages;

      for (const pageName in oldPages) {
        if (oldPages.hasOwnProperty(pageName)) {
          if (pageName === oldName) {
            newPages[name] = oldPages[pageName];
          } else {
            newPages[pageName] = oldPages[pageName];
          }
        }
      }

      if (oldName === state.currentPage) {
        rxCurrentPage.next(name);
        const url = `/edit/edit/${state.userName}/${state.pageId}/${name}`;
        history.push(url);
      }

      rxPages.next(newPages);
      rxNavigation.next(navigation);
    })
    .catch((error) => {
      eventEmiter.next({
        type: 'add-alert',
        payload: {
          type: 'error',
          text: 'Error during saving',
          description: error.message,
        },
      });
    })
    .finally(() => {
      rxIsLoading.next('');
    });
};
