import { AUTH_USER_TOKEN_KEY, UPLOAD_API } from '../Constants';
import { createBrowserHistory } from 'history';
import { eventEmiter } from '../rx/rxState';
import { validateToken } from '../Utils';
import jwtDecode from 'jwt-decode';

const SUB_TOKEN_NAME = 'SUB_TOKEN_NAME';
const history = createBrowserHistory();

/**
 * @param {string} url
 * @param {'GET'|'POST'|'PUT'} method
 * @param {object} [data]
 *
 * @returns {Promise<any>}
 */
export const api = async (url, method = 'GET', data = null, headers = {}) => {
  let token;
  const commonHeaders = {
    'Content-Type': 'application/json',
  };
  if (data?.token) {
    token = await validateToken(data.token);
    commonHeaders['Authorization'] = token;
  }
  const payload = {
    method,
    headers: {
      ...commonHeaders,
      ...headers,
    },
    body: JSON.stringify({
      ...data,
      token,
    }),
  };
  try {
    const result = await fetch(`${url}`, payload);
    const data = await result.json();
    if (data.error) {
      handleApiError(data.error);
      throw data.error;
    } else {
      return data;
    }
  } catch (err) {
    handleApiError(err);
    throw err;
  }
};

const handleApiError = (error) => {
  console.log('API error', error);
  if (error === 'token expire') {
    console.error('TOKEN EXPIRED');
    // let location = history.location.pathname;
    // const pos = location.indexOf('/', 7);
    // location = '/console/login' + location.substr(pos);
    // eventEmiter.next({
    //   type: 'redirect',
    //   payload: location
    // });
  }
};

export const getToken = () => {
  const subToken = getSubToken();
  if (subToken) {
    return subToken;
  }
  return localStorage.getItem(AUTH_USER_TOKEN_KEY);
};

export const getSubToken = () => {
  return localStorage.getItem(SUB_TOKEN_NAME);
};

export const getUserRole = () => {
  return localStorage.getItem('USER_ROLE');
};

export const isAdmin = () => {
  return getUserRole() === 'admin';
};

export const username = (token) => {
  if (!token) {
    return '';
  }
  try {
    const decodedJwt = jwtDecode(token);
    return decodedJwt.sub;
    // return decodedJwt.username;
  } catch (e) {
    console.error('error parsing token', e);
    return '';
  }
};

export const getUserId = () => {
  return localStorage.getItem('USER_ID');
};

export const uploadGeneratedImage = async (generatedUrl) => {
  const response = await api(`${UPLOAD_API}/upload-link`, 'POST', {
    url: generatedUrl,
  });
  return response.url;
};

export const emulatePause = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, time);
  });
};
