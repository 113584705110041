import { eventEmiter } from '../rxState';
import { PAGECRAFT_API_URL } from '../../Constants';
import { Buffer } from 'buffer';
import { getToken } from '../../utils/api';
import { validateToken } from 'Utils';

function dataURItoBlob(dataURI) {
  var byteString = Buffer.from(dataURI.split(',')[1], 'base64');
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // var ab = new ArrayBuffer(byteString.length);
  // var ia = new Uint8Array(ab);
  // console.log(byteString)
  // for (var i = 0; i < byteString.length; i++) {
  // ia[i] = byteString.charCodeAt(i);
  // }
  return new Blob([byteString], { type: mimeString });
}

export const saveSection = async (state, { data, image }) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  formData.append('name', `section-` + Math.ceil(Math.random() * 100));
  formData.append('file', dataURItoBlob(image));
  const token = await validateToken(getToken());
  fetch(`${PAGECRAFT_API_URL}/section`, {
    method: 'POST',
    headers: {
      authorization: token,
    },
    body: formData,
  })
    .then((res) => res.json())
    .then(() => {
      eventEmiter.next({
        type: 'add-alert',
        payload: {
          type: 'success',
          text: 'Section saved',
        },
      });
    })
    .catch((error) => {
      eventEmiter.next({
        type: 'add-alert',
        payload: {
          type: 'error',
          text: 'API save section error',
          description: error.message,
        },
      });
    });
};
