import React from 'react';
import { Block, BlockStyle } from './Block';
import styled from 'styled-components';
import StoreController from '../../StoreController';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { API_STRIPE } from '../../Constants';
import { rxProjectId, rxProducts, rxStripeKey } from '../../rx/rxState';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';

export const BlockStripeStyle = styled.div`
  padding: 10px;
  border: 1px solid #dadada;
  background-color: white;
  pointer-events: auto;
`;

const callLinkMilk = (customerEmail) => {
  if (!customerEmail) {
    console.error('LINK_MILK_ERROR - no customer Email');
    return;
  }

  const options = {
    type: 'Purchase',
    status: 'Active',
    match_with: 'subscription',
    livemode: true,
  };

  try {
    window
      .LinkMink('pub_live_ZVwmhZX84l3pn2MY9RCw')
      .conversion(customerEmail, options)
      .then();
  } catch (e) {
    console.error('LINK_MILK_ERROR', e);
  }
};

const callRewardfull = (customerEmail) => {
  if (!customerEmail) {
    console.error('REWARDFULL - no customer Email');
    return;
  }
  try {
    window.rewardful('convert', { email: customerEmail });
  } catch (e) {
    console.error('REWARDFULL', e);
  }
};

export class BlockStripeProxy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputState: 'null', // 'null' means empty, 'invalid' - means empty or invalid (email format for example), 'good' - means good
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.props.reference.current.style.cssText = this.props.styleText();
    this.props.callback({
      verify: () => this.verify(),
      createPaymentIntent: (data, callback) =>
        this.createPaymentIntent(data, callback),
      savePaymentMethodAndSubscribe: (data, callback) =>
        this.savePaymentMethodAndSubscribe(data, callback),
    });
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.styleText !== this.props.styleText) {
    this.props.reference.current.style.cssText = this.props.styleText();
    // }
  }

  savePaymentMethodAndSubscribe(data, callback) {
    let customerEmail;
    console.log('savePaymentMethodAndSubscribe');
    var thiz = this; //TODO: fix this weird workaround. see code inside .then block

    const cardElement = this.props.elements.getElement(CardElement);
    this.props.stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement,
        // billing_details: {
        //     name: cardholderName.value,
        // }
      })
      .then((result) => {
        if (result.error) {
          console.log('ERROR:', result.error);
          callback(false, result.error.message);
          this.setState({ errorMessage: result.error.message });
        } else {
          console.log('Created payment method: ' + result.paymentMethod.id);
          const payload = {
            paymentMethod: result.paymentMethod.id,
            projectId: rxProjectId.getValue(),
            ...data,
          };
          if (payload['First Name']) {
            payload.FirstName = payload['First Name'];
            delete payload['First Name'];
          }
          if (payload['Last Name']) {
            payload.LastName = payload['Last Name'];
            delete payload['Last Name'];
          }
          if (payload.Email) {
            customerEmail = payload.Email;
          }
          graphQlCall({
            queryTemplateObject: QUERIES.AF_SUBSCRIBE,
            values: {
              ...payload,
              lm_data: window.lm_data,
            },
          }).then((data) => {
            if (data.error) {
              callback(false, data.error);
              this.setState({ errorMessage: data.error });
              return;
            } else {
              callback(true, null);
              thiz.setState({ errorMessage: '' });
              callRewardfull(customerEmail);
              // callLinkMilk(customerEmail);
            }
          });
        }
      });
  }

  createPaymentIntent(data, callback) {
    let customerEmail;
    this.setState({ errorMessage: '' });
    const payload = {
      projectId: rxProjectId.getValue(),
      ...data,
    };
    if (payload['First Name']) {
      payload.FirstName = payload['First Name'];
      delete payload['First Name'];
    }
    if (payload['Last Name']) {
      payload.LastName = payload['Last Name'];
      delete payload['Last Name'];
    }
    if (payload.Email) {
      customerEmail = payload.Email;
    }
    graphQlCall({
      queryTemplateObject: QUERIES.AF_CREATE_PAYMENT_INTENT,
      values: { ...payload, lm_data: window.lm_data },
    })
      .then((data) => {
        // console.log('data:',data);
        if (data.error) {
          callback(false, data.error);
          this.setState({ errorMessage: data.error });
          return;
        }
        var thiz = this; //TODO: fix this weird workaround. see code inside .then block

        const cardElement = this.props.elements.getElement(CardElement);
        this.props.stripe
          .confirmCardPayment(data.client_secret, {
            payment_method: {
              card: cardElement,
            },
            setup_future_usage: 'off_session',
          })
          .then(function (result) {
            if (result.error) {
              // console.log('this:', thiz);
              callback(false, result.error.message);
              thiz.setState({ errorMessage: result.error.message });
              console.log('ORDER ERROR', result.error.message);
            } else {
              callback(true, null);
              thiz.setState({ errorMessage: '' });
              callRewardfull(customerEmail);
              // callLinkMilk(customerEmail);
              // console.log('ORDER COMPLETE');
              // console.log('payment method:', result.paymentIntent.payment_method);
            }
          });
      })
      .catch((error) => {
        callback(false, error);
        this.setState({ errorMessage: error });
        console.log('error', error);
      });

    return true;
  }

  verify() {
    return true;
  }

  render() {
    let content;
    if (StoreController.instance().liveMode) {
      //LIVE Mode
      content = (
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '18px',
                color: '#424770',
                '::placeholder': {
                  color: '#939292',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      );
    } else {
      //Dummy Preview
      content = (
        <div style={{ height: '30px', background: '#99a5ff', display: 'flex' }}>
          <div style={{ margin: 'auto' }}>STRIPE FORM PLACEHOLDER</div>
        </div>
      );
    }

    return (
      <BlockStyle
        id={this.props.id}
        key={this.props.id}
        ref={this.props.reference}
        isDragging={this.props.isDragging}
        className={this.props.className}
      >
        {this.props.children}

        <BlockStripeStyle>{content}</BlockStripeStyle>

        <div style={{ color: '#ff5172', height: '30px' }}>
          {this.state.errorMessage}
        </div>
      </BlockStyle>
    );
  }
}

export class BlockStripe extends Block {
  constructor(props) {
    super(props);

    this.type = 'Stripe';

    let attribute = {
      id: 'product',
      displayName: 'Product',
      value: '',
      type: 'AttributeDropdown',
      options: [],
    };
    this.addAttribute(attribute);

    attribute = {
      id: 'price',
      displayName: 'Price',
      value: '',
      type: 'AttributeDropdown',
      options: [],
    };
    this.addAttribute(attribute);

    rxProducts.subscribe({
      next: (v) => this.updateProducts(v),
    });

    this.updateProducts(rxProducts.value);

    this.callback = null;

    const stripeKey = rxStripeKey.getValue();
    this.stripePromise = loadStripe(stripeKey);
  }

  updateProducts(products) {
    let productOptions = [];
    for (let product of products) {
      let id;
      if (product.id) {
        id = product.id;
      } else {
        id = product.name;
      }
      productOptions.push({
        label: product.name,
        value: id,
      });
    }
    this.product.options = productOptions;
  }

  callbackHandler(callback) {
    this.callback = callback;
  }

  createPaymentIntent(data, callback) {
    let dict = {};
    for (const d of data) {
      dict[d.type] = d.value;
    }
    let currentProduct = null;
    for (const prod of rxProducts.getValue()) {
      if (prod.name === this.product.value) {
        currentProduct = prod;
      }
    }

    if (currentProduct && currentProduct.isSevenDayTrial) {
      return this.callback.savePaymentMethodAndSubscribe(
        {
          ...dict,
          productID: this.product.value,
          priceID: this.price.value,
        },
        callback
      );
    } else {
      return this.callback.createPaymentIntent(
        {
          ...dict,
          productID: this.product.value,
          priceID: this.price.value,
        },
        callback
      );
    }
  }

  update() {
    super.update();
    let priceOptions = [];
    for (const product of rxProducts.value) {
      let id;
      if (product.id) {
        id = product.id;
      } else {
        id = product.name;
      }
      if (id === this.product.value) {
        if (product.prices) {
          for (const price of product.prices) {
            priceOptions.push({
              label: price.unit_amount / 100 + ' ' + price.currency,
              value: price.id,
            });
          }
          this.price.visible = true;
        } else {
          this.price.visible = false;
        }
        break;
      }
    }

    this.price.options = priceOptions;
  }

  verifyInput() {
    return this.callback.verify();
  }

  renderView() {
    return (
      <Elements stripe={this.stripePromise} key={this.id}>
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <>
              {/* { this.isDragging ? this.getPlaceholder() : null} */}
              <BlockStripeProxy
                stripe={stripe}
                isDragging={this.isDragging}
                elements={elements}
                id={this.id}
                key={this.id}
                view={this.view}
                reference={this.ref}
                ref={this.proxyRef}
                styleText={this.style}
                callback={(callbacks) => this.callbackHandler(callbacks)}
                className={this.className}
                product={this.product}
              />
            </>
          )}
        </ElementsConsumer>
      </Elements>
    );
  }
}
